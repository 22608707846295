<template>
  <div class="app-container">
  <a-spin :spinning="loading">
    <!-- <div class="menu-nav">
      <div>
        <a-icon type="setting" class="menu-nav-icon"></a-icon>
        <span class="menu-nav-text">数据集管理</span>
      </div>
      <a-button-group>
        <a-button style="" type="primary" icon="plus-square" v-hasPermi="['aidataset_add']" @click="handleAdd">新增</a-button>
        <a-button style="margin-left:10px;" type="primary" v-hasPermi="['aidataset_delete']" icon="delete" @click="handleDelete" :disabled="multiple">删除</a-button>
      </a-button-group>
    </div> -->
    <div class="table-search">
       <a-form-model :model="queryParams" ref="queryForm" size="small" layout="inline" v-show="showSearch" label-width="68px">
          <a-form-model-item label="数据集名称" prop="name">
            <a-select v-model="queryParams.name" style="width:200px;" placeholder="请选择数据集名称" allowClear show-search>
                <a-select-option v-for="item in datasetList" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
            </a-select>
        </a-form-model-item>
          <a-form-model-item>
            <a-button icon="search" type="primary"  @click="handleQuery">查询</a-button>
            <a-button icon="a-icon-refresh" @click="resetQuery" style="margin-left: 10px">重置</a-button>
          </a-form-model-item>
          <a-form-model-item style="float: right;margin-right: 0px;">
            <a-button style="" type="primary" icon="plus-square" v-hasPermi="['aidataset_add']" @click="handleAdd">新增</a-button>
            <a-button style="margin-left:10px;" type="primary" v-hasPermi="['aidataset_delete']" icon="delete" @click="handleDelete" :disabled="multiple">删除</a-button>
          </a-form-model-item>
        </a-form-model>
    </div>
    <div class="card" style="padding:6px ;height:calc(100vh - 160px)">
    <a-table rowKey="id" :columns="columns" :dataSource="tableList" :row-selection="rowSelectionConfig"
               ref="table" :pagination="{
        defaultCurrent: queryParams.pageNum, // 默认当前页数
        defaultPageSize: queryParams.pageSize, // 默认当前页显示数据的大小
        total: total, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['20', '50', '100'],
        showTotal: (total, range) => `每页${queryParams.pageSize}条，共 ${total} 条`, // 显示总数
        onShowSizeChange: onPageSizeChange,
        // 改变每页数量时更新显示
        onChange: onPageSizeChange,
      }" bordered>
        <template v-slot:action="record">
            <a href="javascript:;" @click="handleView(record)" v-hasPermi="['aidataset_view']" style="margin-right: 10px;"><a-icon type="eye" /> 查看</a>
          <a href="javascript:;" @click="handleUpdate(record)" v-hasPermi="['aidataset_edit']" style="margin-right: 10px;"><a-icon type="form" /> 编辑</a>
          <a href="javascript:;" @click="handleDelete(record)" v-hasPermi="['aidataset_delete']"><a-icon type="delete" /> 删除</a>
        </template>
      </a-table>
      <div style="position: relative;margin-left: 6px;">注意：数据集不少于100张</div>
    </div>

        <!-- 添加或修改AI模型管理对话框 -->
        <ai-data-set-dialog :show-modal="open" :report-data="detailData" :handelType="handelType" @onFresh="onFresh" @close="closeDialog"/>
    </a-spin>
  </div>
</template>

<script>
import {message, Modal} from 'ant-design-vue';
import { optionNameList, queryList, deleteDataSet } from "@/api/ai/dataSet.js";
import AiDataSetDialog from "./AiDataSetDialog.vue";

export default {
  name: "AiDataSet",
  components: {
    AiDataSetDialog
  },
  data() {
    return {
        columns: [
        {
          title: '数据集名称',
          dataIndex: 'name',
        },
        {
          title: '图像数量',
          dataIndex: 'imageNum',
        },
        {
          title: '标签文件数量',
          dataIndex: 'fileNum',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
        },
        {
          title: '上传人',
          dataIndex: 'createBy',
        },
        {
          title: '操作',
          key: 'operation',
          width: '180px',
          align:'center',
          className: 'operation-cls',
          scopedSlots: {customRender: 'action'}
        }
      ],
      selectedKeys: [],
      datasetList: [],
      totalData: {},
      // 遮罩层
      loading: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // AI模型管理表格数据
      tableList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 表单参数
      detailData: {},
      // 表单校验
      rules: {
        name: [
          { required: true, message: "模型名称不能为空", trigger: ["change", "blur"] }
        ],
        modelVersion: [
          { required: true, message: "模型版本信息不能为空", trigger: ["change", "blur"]}
        ],
      },
      handelType: 'add'
    };
  },
  created() {
    this.getList();
    this.optionNameList();
  },
  computed: {
    rowSelectionConfig() {
      return {
        selectedRowKeys: this.selectedKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedKeys = selectedRowKeys;
           this.multiple = !this.selectedKeys.length
        }
      }
    }
  },
  methods: {
    // 获取数据集名称
    optionNameList() {
        optionNameList().then(res => {
            if(res.success && res.data) {
                this.datasetList = res.data || [];
            }
        });
    },
    /** 查询AI模型管理列表 */
    getList() {
      this.loading = true;
      queryList(this.queryParams).then(response => {
        this.tableList = response.rows.map(ele => {
          this.totalData[ele.id] = ele.name;
          return ele;
        });
        this.total = response.total;
        this.loading = false;
      });
    },
    onFresh() {
      this.getList();
      this.optionNameList();
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      // const that = this;
      // this.$nextTick(() => {
      //   that.$refs["form"].resetFields();
      // });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.selectedKeys = [];
      this.totalData = [];
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.detailData = {};
      this.open = true;
      this.title = "新增数据集";
      this.handelType = 'add';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.detailData = row;
      this.open = true;
      this.title = "编辑数据集";
      this.handelType = 'update';
    },
    /** 查看 */
    handleView(row) {
      this.detailData = row;
      this.open = true;
      this.title = "查看数据集";
      this.handelType = 'view';
    },
    /** 关闭弹框 */
    closeDialog(){
        this.open = false;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids =  this.selectedKeys;
      if(row.id) {
        ids = [row.id];
      }
      const names = ids.map(ele => this.totalData[ele]);
      const that = this;
      Modal.confirm({
        title: '提示',
        content: '是否确认删除数据集名称为【' + names + '】的数据项？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          deleteDataSet(ids).then(res => {
            if(res.success) {
              that.selectedKeys = [];
              that.multiple = true;
              that.getList();
              message.success("删除成功");
            }
          });
        }
      });
    },
    onPageSizeChange(current, pageSize){
      this.queryParams = {
        ...this.queryParams,
        pageNum: current,
        pageSize,
      };
      this.getList();
    },
  }
};
</script>
